import React from "react";
import { Link, useLocation } from "react-router-dom";
import logo from './AGENCY (2).png';

function Header() {
  const location = useLocation();

  // Function to check if the current route is active
  const isActive = (path) => (location.pathname === path ? "active" : "");

  return (
    <div>
      {/* Topbar Start */}
      <div className="container-fluid bg-dark px-5 d-none d-lg-block">
        <div className="row gx-0">
          {/* Contact Info */}
          <div className="col-lg-8 text-center text-lg-start mb-2 mb-lg-0">
            <div
              className="d-inline-flex align-items-center"
              style={{ height: 45 }}
            >
              <small className="me-3 text-light">
                <i className="fa fa-map-marker-alt me-2" />
                Shkoder, Albania
              </small>
              <small className="me-3 text-light">
                <i className="fa fa-phone-alt me-2" />
                +355 69 77 86 777 | +355 68 430 00 88
              </small>
              <small className="text-light">
                <i className="fa fa-envelope-open me-2" />
                Siguracione | Udhetime
              </small>
            </div>
          </div>
          {/* Social Media Links */}
          <div className="col-lg-4 text-center text-lg-end">
            <div
              className="d-inline-flex align-items-center"
              style={{ height: 45 }}
            >
              <a
                className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
                href="https://www.facebook.com/27agency27/"
                target="_blank"
                rel="noopener noreferrer"
                title="Visit our Facebook page"
              >
                <i className="fab fa-facebook-f fw-normal" />
              </a>
              <a
                className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
                href="https://www.instagram.com/27agency27/"
                target="_blank"
                rel="noopener noreferrer"
                title="Visit our Instagram page"
              >
                <i className="fab fa-instagram fw-normal" />
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* Topbar End */}

      {/* Navbar Start */}
      <div className="container-fluid position-relative p-0">
        <nav className="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0">
          {/* Brand Logo */}
          {/* Brand Logo */}
          <Link to="/" className="navbar-brand p-0">
            <img
    src={logo}
    alt="Agency 27 Logo"
              className="img-fluid"
              style={{ maxHeight: "100px" }}
            />
          </Link>
          {/* Navbar Toggle */}

          {/* Navbar Toggle */}
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
          >
            <span className="fa fa-bars" />
          </button>
          {/* Navbar Links */}
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav ms-auto py-0">
              <Link to="/" className={`nav-item nav-link ${isActive("/")}`}>
                Home
              </Link>
              <Link
                to="/About"
                className={`nav-item nav-link ${isActive("/About")}`}
              >
                About
              </Link>
              <Link
                to="/Services"
                className={`nav-item nav-link ${isActive("/Services")}`}
              >
                Services
              </Link>
              <Link
                to="/Packages"
                className={`nav-item nav-link ${isActive("/Packages")}`}
              >
                Packages
              </Link>
              {/* Dropdown Menu */}
              <div className="nav-item dropdown">
                <Link
                  to="#"
                  className="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  More
                </Link>
                <div className="dropdown-menu m-0">
                  <Link to="/Destination" className="dropdown-item">
                    Destination
                  </Link>
                  <Link to="/Booking" className="dropdown-item">
                    Booking
                  </Link>
                  <Link to="/Team" className="dropdown-item">
                    Travel Guides
                  </Link>
                  {/* <Link to="/Testimonial" className="dropdown-item">
                    Testimonial
                  </Link> */}
                  {/* <Link to="/Error" className="dropdown-item">
                    404 Page
                  </Link> */}
                </div>
              </div>
              <Link
                to="/Contact"
                className={`nav-item nav-link ${isActive("/Contact")}`}
              >
                Contact
              </Link>
            </div>
          </div>
        </nav>
      </div>
      {/* Navbar End */}
    </div>
  );
}

export default Header;
